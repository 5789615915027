import React from "react"
import {Link} from "gatsby"
import {useStaticQuery, graphql} from "gatsby"
// import Layout from "../components/layout"
// import SEO from "../components/seo"
import Img from "gatsby-image"
import {slugify} from "../helpers"

const Blog = (props) => {
    const data = useStaticQuery(graphql`
    query {
      allContentfulArticles {
        edges {
          node {
            body {
              body
            }
            id
            title
            intro
            cover {
              localFile {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

    const articles = data.allContentfulArticles.edges;
    return (
        <div>
            <div className="mx-auto max-w-7xl font-sans flex items-center justify-around ">
                    {/*<h1 className={`text-xl font-bold text-purple-800 mb-4 text-left `}>From our blog</h1>*/}
                    <div className={'flex flex-wrap'}>
                        {articles.map(({node: article}, i) => {
                            return i < 3 && <div className={'flex mx-auto items-center lg:items-start p-4 flex flex-col justify-start text-navy-600  max-w-sm'} key={article.id}>
                                <div className={'w-full  mb-2'}>
                                    <Img fluid={article.cover.localFile.childImageSharp.fluid} className="w-full mb-2 w-64  mx-auto rounded" alt={article.title}/>
                                </div>
                                <Link to={'/blog/' + slugify(article.title)} aria-label={article.title + ' Nunuma'}>
                                    <h2 className="font-bold text-base leading-snug mb-2">{article.title}</h2>
                                </Link>
                            </div>
                        })}
                    </div>
            </div>
        </div>
    )
}

export default Blog