import React from "react"
import {Link} from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
// import LeadForm from "../components/leadform";
// import MobileLeadingForm from "../components/mobileleadform";
import Blog from "../components/landingblog";
import {graphql, useStaticQuery} from "gatsby";
import Img from "gatsby-image";
import {slugify} from "../helpers";

const CaseStudies = (props) => {
    const data = useStaticQuery(graphql`
        query {
          allContentfulCaseStudy {
            edges {
              node {
                body {
                  body
                  id
                }
                id
                intro {
                  intro
                }
                title
                isHighlighted
                cover {
                  localFile {
                    url
                    childImageSharp {
                      fluid {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                }
              }
            }
          }

        }
    `);

    return (
        <Layout>
            <SEO title="Articles" description={`Web application development and business digital transformation blog. A dive on best practices, web security, application performance and next gen digital experiences.`}/>
            <div className={'relative'}><div className={'hidden xxl:block purple-rotated-div'}/></div>
            <div className={'relative'}><div className={'hidden xxl:block gray-rotated-div'}/></div>
            {/*<div className={'hidden lg:block'}>*/}
            {/*    <LeadForm/>*/}
            {/*</div>*/}
            {/*<div className={'block lg:hidden'}>*/}
            {/*    <MobileLeadingForm/>*/}
            {/*</div>*/}
            <div className={'mx-auto max-w-7xl text-purple-800 px-4'}>
                <h1 className={'max-w-2xl text-4xl font-bold leading-snug mb-4'}>Case studies</h1>
                <h3 className={'max-w-3xl mb-8'}>We help businesses grow through web solutions that increase sales, reduce costs and optimize operations. We know the challenges and opportunities the web offers. Let’s build your custom website together, craft your unique e-commerce experience and get your </h3>
                {data.allContentfulCaseStudy.edges.map(({node: caseStudy}, i) => (<div key={i} className={'flex flex-col lg:flex-row my-20'}>
                    <div className={'pr-4 w-full lg:w-2/5 mb-2 lg:mb-0'}>
                        <Img fluid={caseStudy.cover.localFile.childImageSharp.fluid} className="max-w-3xl mb-8 mx-auto "/>
                    </div>
                    <div className={'p-0 lg:pl-4 w-full lg:w-3/5 flex flex-col justify-between'}>
                        <div className={'mb-4 lg:mb-0'}>
                            <p className={'uppercase font-bold'}>Case Studies</p>
                            <p className={'text-3xl font-bold leading-7 '}>{caseStudy.title}</p>
                        </div>
                        <p className={'max-w-lg '}>{caseStudy.intro.intro}</p>
                        <Link to={'/case-study/' + slugify(caseStudy.title)} className={'max-w-lg mb-8 font-bold'}>Learn more about our services</Link>
                    </div>
                </div>))}
            </div>

            {/*<div className={'hidden lg:block'}>*/}
            {/*    <LeadForm/>*/}
            {/*</div>*/}
            {/*<div className={'block lg:hidden'}>*/}
            {/*    <MobileLeadingForm/>*/}
            {/*</div>*/}
            <div className={''}>
                <Blog/>
            </div>
        </Layout>
    )
}

export default CaseStudies